import React from 'react'

const ProgressHome = () => {
  return (
    <>
<div className="container">
    
</div>
    </>
  )
}

export default ProgressHome