import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import NewHeader from '../components/Header/NewHeader'

const CurrentStudents = () => {
  return (
    <div>
    <NewHeader/>
    CurrentStudents
    <Footer/>
    </div>
  )
}

export default CurrentStudents