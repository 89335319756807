import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranslations } from '../actions/languageActions';
import Loading from "./loading/Loading";

const Testimonial = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);

  const [currentSlide, setCurrentSlide] = useState(1);
  const slideRefs = useRef([]);

  const autoRotate = () => {
    setCurrentSlide(prevSlide => prevSlide === 4 ? 1 : prevSlide + 1);
  };

  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);

  useEffect(() => {
    const interval = setInterval(autoRotate, 5000);
    return () => clearInterval(interval); // Cleanup function to clear the interval when the component unmounts
  }, []); // Empty dependency array means this effect runs only once after the initial render

  useEffect(() => {
    slideRefs.current.forEach((ref, index) => {
      if (ref && index + 1 === currentSlide) {
        ref.checked = true;
      }
    });
  }, [currentSlide]); // Run this effect whenever currentSlide changes

  if (!translations) {
    return <div><Loading /></div>; // Show loading state while translations are being fetched
  }

  const { homepage } = translations;
  const data = homepage.testimonalas.list;

  return (
    <div className="dizme_tm_section pt-80" id="testimonal-sec">
      <div className="dizme_tm_testimonials">
        <div className="container">
          <div className="dizme_tm_main_title row" data-align="left">
            <div className="col-md-8">
              <span>{homepage.testimonalas.title}</span>
              <h3>{homepage.testimonalas.titleTwo}</h3>
              <p>{homepage.testimonalas.description}</p>
              <div className="list_wrapper">
                <div className="total">
                  <div className="in">
                    <div className="testimonial_slider_2">
                      {[1, 2, 3].map(index => (
                        <input
                          key={index}
                          type="radio"
                          name="slider_2"
                          id={`slide_2_${index}`}
                          ref={el => (slideRefs.current[index - 1] = el)}
                        />
                      ))}
                      <div className="boo_inner clearfix">
                        {data.map((item, index) => (
                          <div key={index} className="slide_content">
                            <div className="testimonial_2">
                              <div className="content_2">
                                <p>{item.details}</p>
                              </div>
                              <div className="author_2">
                                <h3>{item.name}</h3>
                                <span>{item.profession}</span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div id="controls">
                        {[1, 2, 3].map(index => (
                          <label key={index} htmlFor={`slide_2_${index}`}></label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <img src="/img/thumbs/feedback.png" alt="testimonal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
