import React from 'react';

const LogoSlider = () => {
  return (
    <div className="slider">
        <div className="slide-track">
        <div className="slide">
            <img src="/images/ip/tb.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/tew.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/tlc.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/vpsc.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/ss.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/cura.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/dp.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/acwa.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/hf.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/web.png" width="185" alt="" />
        </div>
        <div className="slide">
            <img src="/images/ip/sw.png" width="185" alt="" />
        </div>
        </div>
    </div>
  );
};

export default LogoSlider;
