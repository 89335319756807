import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
// import Typewriter from "typewriter-effect";

const PrivacyPolicyPage = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);

  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);

  useEffect(() => {
    // Add Zoho chat script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqscript';
    script.defer = true;
    script.src = 'https://salesiq.zohopublic.com.au/widget';
    document.body.appendChild(script);

    const chatScript = document.createElement('script');
    chatScript.type = 'text/javascript';
    chatScript.id = 'zsiqchat';
    chatScript.innerHTML = `var $zoho=$zoho || {}; $zoho.salesiq = $zoho.salesiq || {widgetcode: "8f3652f154f4b9b4b4caf90f44a92524c555b8bd3c196ed488d8d454280b2a0f30ea8b0f463e4d0ad47d78a923021415", values:{}, ready:function(){}};`;
    document.body.appendChild(chatScript);

    return () => {
      // Clean up script on component unmount
      document.getElementById('zsiqscript')?.remove();
      document.getElementById('zsiqchat')?.remove();
    };
  }, []);

  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }

  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Best Certificate & Diploma Courses | Signet Institute</title>
        <meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.' data-rh="true" />
      </Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec-">
        <section className="pp-page-section pt-120 pb-65 rpt-90">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4>This Privacy Policy and Notice outlines our approach to ensuring the privacy of personal information gathered through the website.</h4>
                <p>Signet Institute of Australia Pty Ltd (ABN 37 135 002 167 RTO CODE: 40898 CRICOS Code:03377J) upholds your privacy rights by collecting students' personal information through fair and legal means necessary for enrollment and administration purposes at the Institute. We are committed to maintaining the confidentiality and security of all student data as per the Commonwealth Privacy Act (2000). Our Privacy Policy outlines the procedures regarding the collection, storage, usage, and dissemination of student information, referencing our record management system.</p>
                <p>This Privacy Policy & Notice explains how Signet Institute of Australia respects and protects your privacy rights, detailing how we collect, share, and utilise your personal information, and how you can exercise your privacy rights. This policy applies to all data sources, including information gathered through https://signet.edu.au, whether received directly or indirectly.</p>
                <p>We reserve the right to update this Privacy Policy & Notice periodically, with changes communicated through the Student Handbook on our website, aligned with the Australian Privacy Principles. The policy delineates the types and purposes of personal data collection, processing procedures, and measures ensuring its security. For any inquiries or concerns regarding your personal information usage, feel free to contact us using the details provided at the end of this notice.</p>
              </div>
              <div className="col-md-12">
                <h4>1. Privacy Notice:</h4>
                <p>Signet Institute of Australia ensures the gathering of personal information through fair and lawful means, essential for marketing, enrollment and administrative purposes. Committed to upholding confidentiality and security, the Institute adheres to the guidelines outlined in the Commonwealth Privacy Act (2000). Additionally, the Institute's Privacy Policy delineates procedures governing the collection, storage, utilisation, and dissemination of private information, with particular reference to the record management system.</p>
                <h4>2. Why we collect your personal information:</h4>
                <p>As a registered training organisation (RTO), we collect your personal information to facilitate and manage marketing activities and enrollment in vocational education and training (VET) courses. Your provision of personal information to Signet Institute of Australia is voluntary, but refusal may affect your enrollment process.</p>
                <h4>3. How we use your personal information:</h4>
                <p>We utilise your personal information to market and deliver VET courses and fulfill our obligations as an RTO.</p>
                <h4>4. How we disclose your personal information:</h4>
                <p>We are legally obligated to disclose the personal information collected to the National VET Data Collection managed by the National Centre for Vocational Education Research Ltd (NCVER) under the National Vocational Education and Training Regulator Act 2011 (Cth) (NVETR Act). Additionally, we may disclose your information to relevant state or territory training authorities as mandated by law.</p>
                <h4>5. The NCVER and other entities handling your personal information:</h4>
                <p>The NCVER handles your personal information in accordance with relevant laws, including the Privacy Act 1988 (Cth) and the NVETR Act. Your information may be used for various marketing activities and for educational purposes, including research and statistical analysis. NCVER may share information with government departments and authorised third parties for specific purposes outlined in their Privacy Policy.</p>
                <h4>6. Surveys:</h4>
                <p>You may receive surveys conducted by government departments or NCVER. You can opt out of these surveys when contacted.</p>
                <h4>7. Collection and Utilisation of Personal Information</h4>
                <p>• Providing Assistance and Support: We share your information with our network of industry partners, consultants, and third-party contractors to aid in managing your progress effectively and catering to your individual requirements. All involved parties are bound by our Privacy Policy, Notice, and the Australian Privacy Principles.</p>
                <p>• Provision of Information and Services: Information, products, and services are provided to you, and we may request information to administer these offerings, including enrollment, assessment, certification issuance, and related activities.</p>
                <p>• Responding to Inquiries: We address your queries concerning our website, products, advice, and services.</p>
                <p>• Improvement and Customisation: Your information aids us in better understanding your needs, thus enabling us to enhance our products and services. Additionally, we customise our website and documents to align with your interests and business requirements.</p>
                <p>• Internal Operations and Communication: We maintain internal records and may circulate promotional emails about new products, services, or special offers, giving you the option to opt out at any time.</p>
                <p>• Market Research and Direct Marketing: We may contact you for market research or direct marketing purposes, with the ability for you to opt out at any time.</p>
                <p>• Ensuring Confidentiality: Medical information, if provided, may be shared with third parties only upon receiving written consent from the student.</p>
                <h4>8. Disclosure of Personal Information</h4>
                <p>We may disclose your personal information under various circumstances, including:</p>
                <p>• Providing progress information to third parties such as employers and mentors.</p>
                <p>• Assisting in functions like student recruitment and work placements.</p>
                <p>• Meeting legal and regulatory obligations, including reporting to government agencies.</p>
                <h4>9. Security</h4>
                <p>We are dedicated to ensuring the security of the information you provide. Robust mechanisms and procedures are in place to safeguard your personal information from misuse, loss, or unauthorized access.</p>
                <h4>10. Privacy Statement Updates</h4>
                <p>We may update this Privacy Policy periodically to ensure compliance with legal requirements. Please check our website regularly for any changes.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      {/* </Fade> */}
    </>
  );
};

export default PrivacyPolicyPage;
