import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
import LogoSlider from "../components/LogoSlider";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const IndustryPartners = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  
  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>Industry Partners | Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec pt-100">
	  <h3 class="text-center pt-60">Industry Partners Reviews</h3>
        <section class="nanhe container container--testimonials">
        <div class="testimonial">
				<p class="testimonial__text mb-25">
        "Amrit was a welcome addition to our nursing team, while fulfilling his clinical placement. I wish Amrit all the very best in his future endeavours."	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Ipswich Hospital </h4>
					</div>
				</div>
			</div>

			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "Students were well prepared and keen for placement. Eager to learn and make the most of placement."
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">LaTrobe Regional Hospital</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "Students were well prepared and eager to learn. Asked for feedback on a regular basis. Were professional in dealings with both staff and patients. Asked questions when unsure. "
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">LaTrobe Regional Hospital</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
				“Jasmine was well supported by Signet through-out placement. If any issues Signet was very easy to contact.”
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">LaTrobe Regional Hospital</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
				“Students were generally well prepared. Probably weren’t expecting the number of abbreviations used including on handover sheet”
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Southwest Healthcare</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
				“Amit was a pleasure to have, He was a very willing participant and will go far in his career”
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Specialist SurgiCentre</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
				“This was a very successful placement. The student was excellent and we have offered employment which has been accepted. The team at Signet are really easy to deal with and we look forward to continuing this relationship”
				</p>
				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Victoria Parade Day Hospital</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
				“Brian is a great student, who was eager and keen to learn. He has a very respectful and friendly manner and is having a very successful placement. The team at Signet provide great communication ad easy to work with -thanks”
				</p>
				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Victoria Parade Day Hospital</h4>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
				“A very confident and capable student. Demonstrated an eagerness to learn and really embraced the clinical placement. Interaction with our team and patients was above average. Yiting would be a great asset to any team. Has a desire to go into mental health and continue her studies. I wish her well. Another great placement by Signet @ VPSC. Thanks to your team”
				</p>
				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Victoria Parade Day Hospital</h4>
					</div>
				</div>
			</div>
        </section>
        
      </div>

	  <div class="container pt-60">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2>Our Industry Partners</h2>
          </div>
          <div class="row pt-30">
		  	<LogoSlider />
          </div>
        </div>
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default IndustryPartners;
