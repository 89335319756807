import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
import FormModalOne from "../elements/FormModalOne";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const FAQ = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  
  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>Frequently Asked Questions | Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec-">
        <section class="pt-150">
        
          <div className="container">
            <div className="row">
              <div className="col-md-8 pt-60">
                <h2 className="title-banner ">
                Frequently Asked Questions
                </h2>
                <p>Welcome to the Frequently Asked Questions (FAQ) section for the Signet Institute of Australia. Here, we aim to provide clear and concise answers to the most common questions our students and prospective students may have. Whether you're looking for information about our programs, enrolment processes, or student services, this section is designed to help you find the answers you need quickly and efficiently.

</p>
              </div>
              <div className="col-md-4">
                  <img src="../img/policy/faq.png" width="350" />
              </div>
            </div>
          </div>
        </section>
        <section class="about-page-section pt-60 pb-65 rpt-90">
            <div className="container">
                <div className="row">
                   <div className="col-md-12 pt-20">
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-1" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-1"><h5>What programs and courses does Signet Institute offer?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Signet Institute provides a diverse range of vocational education and training (VET) programs, including courses in:
                           <ul><li> Automotive</li>
                           <li> Business</li>
                           <li> Construction</li>
                           <li> Community</li>
                           <li> Healthcare</li>
                           <li> General English</li>
                           <li> Manufacturing and Engineering</li>
                            </ul>
                            For detailed information about each program, please visit the respective course
                            pages on our website.
                            </p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-2"><h5>How do I apply for a course at Signet Institute?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                                <p>To apply for a course, you can reach out to us via social media, email, phone, or complete our application form on the website.</p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-3"><h5>What are the entry requirements for Signet Institute courses?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                              <p>Entry requirements vary by course. Generally, applicants need to meet specific educational prerequisites and English language proficiency standards. Detailed requirements are outlined on each course page.</p> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-4" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-4"><h5>What is the duration of the courses offered by Signet Institute?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Course durations vary depending on the program. Some courses may be completed in a few weeks, while others could take several months. Detailed duration information is available on each course page.
</p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-5" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-5"><h5>Does Signet Institute offer support services for students? </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Yes, we offer a range of support services including academic support, counseling,
                            career advice, and assistance with accommodation and student welfare.</p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-6" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-6"><h5>Where are Signet Institute campuses located? </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Our campuses are situated in Melbourne, Adelaide, and Sydney. Each location features modern facilities and resources to enhance student learning.</p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-7" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-7"><h5>How can I contact Signet Institute for more information?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>You can get in touch with us through our social media channels, the contact page on our website, via email, or by calling the provided phone numbers. Specific contact details for each campus are also listed on our website.</p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-8" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-8"><h5>What are the tuition fees for Signet Institute courses?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Tuition fees vary depending on the course and program. Detailed fee information is available on each course page, and prospective students can also contact the admissions office for further details.</p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-9" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-9"><h5>Is Signet Institute of Australia an accredited RTO?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Yes, we are an accredited Registered Training Organisation (RTO) and deliver nationally recognized qualifications.</p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-10" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-10"><h5>What kind of employment can I get after completing my course?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>While a Signet qualification does not guarantee employment, our courses prepare you for entry-level positions or help advance your career to more senior roles across various industries.</p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-11" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-11"><h5>What is the difference between certificate and diploma qualifications?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>While a Signet qualification does not guarantee employment, our courses prepare you for entry-level positions or help advance your career to more senior roles across various industries.
                                <ul>
                                    <li> <strong>Certificate-level Qualifications</strong>: Provide foundational industry-specific skills and knowledge. </li>
                                    <li> <strong>Diploma-level Qualifications</strong>: Equip students with advanced skills, preparing them for mid to senior-level management roles. More details are available on each course page. </li>
                                </ul>
                            </p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-12" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-12"><h5>Will Signet Institute of Australia provide work placement?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>We offer guidance on finding suitable workplaces for practical placements and provide advice and tools to help you approach potential employers in your area. </p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-13" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-13"><h5>I’m an education agency. How can I become an industry partner?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Learn more about becoming a partner and the benefits it offers by contacting our team here. </p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-14" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-14"><h5>What kind of support will I receive during my studies?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>You will have access to a team dedicated to making your learning journey smooth. Our student services handle general inquiries, assist with the learning management system, and help you stay on track with your studies.</p>
                            </div>
                          </div>
                        </div>

                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-15" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-15"><h5>Which industries are in demand?</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Our Fabrication and Community Services courses are designed to meet the demands of these growing industries.</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default FAQ;
