
import Footer from "../components/Footer/Footer";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import { Helmet } from "react-helmet-async";
import FormModalOne from "../elements/FormModalOne";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const GeneralEnglish = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  console.log(selectedLanguage,translations,"test")

  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
    console.log(selectedLanguage,translations,"test")

  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const {generalenglish}= translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>ELICOS | Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec-">
        <section class="pt-150 nanhe">
        
          <div className="container">
            <div className="row">
              <div className="col-md-6 pt-60">
                <h3 className="title-banner h1">
               {generalenglish.title}
                </h3>
                <p>{generalenglish.SubTitle}</p>
              </div>
              <div className="col-md-6 ">
                <div className="banner-ab-image text-center">
                  <img src="/img/policy/elicos.png"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about-page-section pt-60 pb-65 rpt-90">
            <div className="container">
                <div className="row">
                   <div className="col-md-9 pt-20">
                        <div class="whitebg">
                          <h5>{generalenglish.coursedescription}</h5>
                            <p>{generalenglish.descriptiontext}</p>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-2"><h5>{generalenglish.intakedates} </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                                <p>{generalenglish.months}</p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-3"><h5>{generalenglish.modeofdelivery} </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                                <p><ul><li>{generalenglish.modetext1}</li>
                                <li>{generalenglish.modetext2}</li>
                                </ul></p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-4" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-4"><h5>{generalenglish.locationsoffered} </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                              <p><ul><li>{generalenglish.locationsofferedtext1}</li><li>{generalenglish.locationsofferedtext2}</li><li>{generalenglish.locationsofferedtext3}</li>
                            </ul></p> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-5" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-5"><h5>{generalenglish['Additional Signet Requirements']}</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p><ul><li>{generalenglish.Requirements1}</li><li>{generalenglish.Requirements2}</li><li>
                            {generalenglish.Requirements3}</li></ul></p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-6" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-6"><h5>{generalenglish.LLN} </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p><ul><li>{generalenglish.LLNtext1}</li>
                                  <li>{generalenglish.LLNtext2}</li>
                                  <li>{generalenglish.LLNtext3}</li>
                                </ul></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 pt-40 pb-40">
                        <table class="responsive-table">
                              <thead>
                                  <tr>
                                      <th>{generalenglish.levels}</th>
                                      <th>{generalenglish.about_levels}</th>
                                      <th>{generalenglish.pathways}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td data-label="LEVELS">{generalenglish.Elementary_Level_1}</td>
                                      <td data-label="ABOUT THE LEVEL">{generalenglish.level_1_text}</td>
                                      <td data-label="PATHWAYS">{generalenglish.after_1}</td>
                                  </tr>
                                  <tr>
                                      <td data-label="LEVELS">{generalenglish.Preintermediate_Level_2}</td>
                                      <td data-label="ABOUT THE LEVEL">{generalenglish.level_2_text}</td>
                                      <td data-label="PATHWAYS">{generalenglish.after_2}</td>
                                  </tr>
                                  <tr>
                                      <td data-label="LEVELS">{generalenglish.Intermediate_Level_3}</td>
                                      <td data-label="ABOUT THE LEVEL">{generalenglish.level_3_text}</td>
                                      <td data-label="PATHWAYS">{generalenglish.after_3}</td>
                                  </tr>
                                  <tr>
                                      <td data-label="LEVELS">{generalenglish.Upper_Intermediate_Level_4}</td>
                                      <td data-label="ABOUT THE LEVEL">{generalenglish.level_4_text}</td>
                                      <td data-label="PATHWAYS">{generalenglish.after_4}</td>
                                  </tr>
                                  <tr>
                                      <td data-label="LEVELS">{generalenglish.Advanced_Level_5}</td>
                                      <td data-label="ABOUT THE LEVEL">{generalenglish.level_5_text}</td>
                                      <td data-label="PATHWAYS">{generalenglish.after_5}</td>
                                  </tr>
                              </tbody>
                          </table>
                        </div>
                        <div class="row whitebg mt-40">
                          <h4>{generalenglish.Embark_on_Your_Path_to_Transformative_Learning}</h4>
                          <p>{generalenglish.Embark_on_Your_Path_to_Transformative_Learning_text}</p>
                          <div class="col-md-3 text-center">
                          <img src="/img/policy/el-1.png" width="128"/>
                          <p class="text-center">{generalenglish.Embark_icon_1}</p>
                          </div>
                          <div class="col-md-3 text-center">
                          <img src="/img/policy/el-2.png" width="128"/>
                          <p class="text-center">{generalenglish.Embark_icon_2}</p>
                          </div>
                          <div class="col-md-3 text-center">
                          <img src="/img/policy/el-3.png" width="128"/>
                          <p class="text-center"> {generalenglish.Embark_icon_3}</p>
                          </div>
                          <div class="col-md-3 text-center">
                          <img src="/img/policy/el-4.png" width="128"/>
                          <p class="text-center">{generalenglish.Embark_icon_4}</p>
                          </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="course-sidebar rmt-75">
                            <div className="widget widget-course-details wow fadeInUp delay-0-2s text-center">
                                <img src="/img/about/course-details.png" />
                                <ul className="course-details-list mt-20 mb-25">
                                   <li>
                                        <i className="far fa-clock" />{" "}
                                        <span>{generalenglish.Cricos_Code}:</span>{" "}
                                        <b>0100288</b>
                                    </li>
                                    <li>
                                        <i className="far fa-play-circle" />{" "}
                                        <span>{generalenglish.Duration}:</span>{" "}
                                        <b>{generalenglish.Upto_60_weeks}</b>
                                    </li>
                                    </ul>
                                    <p className="">
                                        <FormModalOne  />
                                    </p>
                                    <a href="#popup1" className="theme-btn mt-10">
                                    Book Now <i className="fas fa-arrow-right" />
                                    </a>
                            </div>
                            <div className="widget widget-menu wow fadeInUp delay-0-2s mt--30">
                                <h5 className="widget-title"><a href="https://signet.edu.au/automotive-courses">{generalenglish['Automotive_Courses']} (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/business-courses">{generalenglish['Business_Courses']} (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/construction-courses">{generalenglish['Construction_Courses']} (5)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/community-courses">{generalenglish['Community_Courses']} (5)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/healthcare-courses">{generalenglish['Healthcare_Courses']} (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/general-english">{generalenglish['General_English']} (1)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/manufacturing-and-engineering">{generalenglish['Manufacturing_and_Engineering']} (1)</a></h5>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default GeneralEnglish;
