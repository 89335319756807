import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
import FormModalOne from "../elements/FormModalOne";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const GeneralEnglishSP = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  
  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>ELICOS | Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec-">
        <section class="pt-150 nanhe">
        
          <div className="container">
            <div className="row">
              <div className="col-md-6 pt-60">
                <h3 className="title-banner h1">
                Inglés General (de elemental a avanzado)
                </h3>
                <p>Embrace the opportunity to grow, develop, and achieve your goals with Signet Institute of Australia. Take the first step towards a brighter future by exploring our General English (ELICOS) below and embark on a transformative educational journey like no other.</p>
              </div>
              <div className="col-md-6 ">
                <div className="banner-ab-image text-center">
                  <img src="/img/policy/elicos.png"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about-page-section pt-60 pb-65 rpt-90">
            <div className="container">
                <div className="row">
                   <div className="col-md-8 pt-20">
                        <div class="whitebg">
                          <h5>Course Description</h5>
                            <p>ELICOS is a specially designed qualification for overseas students to enhance their English proficiency. The English language is highly important, not only for the sake of day-to-day communication, but also for business purposes, career orientation and social networking across all industries around the world. SI's ELICOS program is a great way to learn English by building up confidence and developing high literacy skills in the process.</p>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-2"><h5>InTake Dates </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                                <p>January, April, July, October</p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-3"><h5>Mode Of Delivery </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                                <p><ul><li>Classroom</li>
                                <li>Online</li>
                                </ul></p>
                                </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-4" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-4"><h5>Locations Offered </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                              <p><ul><li>Melbourne</li><li>Sydney</li><li>Adelaide</li>
                            </ul></p> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-5" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-5"><h5>Additional Signet Requirements</h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p><ul><li>AGE = 18 years of age at the time of application.</li><li>EDUCATION = Year 12 or equivalent successfully completed.</li><li>
                            COMPUTER = You should possess sound computer skills, ability to navigate internet and use various software tools to study, produce Assessments and absorb the training material.</li></ul></p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-6" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-6"><h5>LLN, PTR, & Computer </h5></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p><ul><li>LLN = Internet-based Language, Literacy and Numeracy test to ensure that you have the fundamental skills to complete this qualification.</li>
                                  <li>PTR = Pre-Training Review is an assessment of your past learning, experience and skill development to ensure that you can understand the concepts delivered in this qualification.</li>
                                  <li>COMPUTER = You should possess sound computer skills, ability to navigate internet and use various software tools to study, produce Assessments and absorb the training material.</li>
                                </ul></p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 pt-40">
                          <img src="/img/policy/el.png" />
                        </div>
                        <div class="col-md-12 pt-40">
                          <img src="/img/policy/tl.jpeg" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="course-sidebar rmt-75">
                            <div className="widget widget-course-details wow fadeInUp delay-0-2s text-center">
                                <img src="/img/about/course-details.png" />
                                <ul className="course-details-list mt-20 mb-25">
                                   <li>
                                        <i className="far fa-clock" />{" "}
                                        <span>CRICOS Code:</span>{" "}
                                        <b>0100288</b>
                                    </li>
                                    <li>
                                        <i className="far fa-play-circle" />{" "}
                                        <span>Duration:</span>{" "}
                                        <b>Up to 60 weeks</b>
                                    </li>
                                    </ul>
                                    <p className="">
                                        <FormModalOne  />
                                    </p>
                                    <a href="#popup1" className="theme-btn mt-10">
                                    Book Now <i className="fas fa-arrow-right" />
                                    </a>
                            </div>
                            <div className="widget widget-menu wow fadeInUp delay-0-2s mt--30">
                                <h5 className="widget-title"><a href="https://signet.edu.au/automotive-courses">Automotive Courses (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/business-courses">Business Courses (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/construction-courses">Construction Courses (5)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/community-courses">Community Courses (5)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/healthcare-courses">Healthcare Courses (3)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/general-english">General English (1)</a></h5>
                                <h5 className="widget-title"><a href="https://signet.edu.au/manufacturing-and-engineering">Manufacturing and Engineering (1)</a></h5>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default GeneralEnglishSP;
