import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const AllReviewsPage = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  
  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>All Reviews| Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec pt-100">
	  <h3 class="text-center pt-60">Feedbacks From Our Students</h3>
        <section class="container container--testimonials">
        <div class="testimonial">
				<p class="testimonial__text mb-25">
        "I am a former student in Certificate III indvidual Support Our instructor Mr. Manish Gaur was a very accomodating and excellent instructor. He helps in every way and exceeds our expectations. looking forward to learn more things in this institution Thank you."	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Joanna Marie Tagle</h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>

			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "I would like to thanks everybody  did an amazing  job and dedicated staffing . I’m glad to be part of  Signet. Thanks to all the staff and supportive mentors for being there for us throughout our journey. Had a great experience and help students participate and become interactive with learning. Thanks a lot."
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Jessie K</h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "I am studying and recommend that students should definitely come here for admission. The teachers and staff are quite humble and it's a perfect place for personality development. The infrastructure,academics and every other aspect is decent to select this college."
				</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Gurneel Kaur</h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "During my internship at Dorevitch Pathology Rowville, | had the privilege of working under the guidance of my mentors, Mel and Leisl. They equipped me with essential skills and knowledge, enabling me to perform blood draws independently and efficiently. Really good experiences. Thank you."	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Dan Huang </h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>

			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "The placement was done accurately with lots of knowledge gained in regards to working with disabilities, schizophrenia and the supervisor was helping throughout the placement in getting as much knowledge with all clients. "	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Arnold Arvindra Kumar </h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "Everyone was willing to help. "	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Ivy San Buenaventura </h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "I learned more skills during my placement. My supervisor was very hands on during my placement. "	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Jan Kelvin Mauricio </h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>
			<div class="testimonial">
				<p class="testimonial__text mb-25">
        "It was good crowd and environment at my work placement. I learnt something new and staff was friendly behaviour for clients. I really enjoyed."	</p>

				<div class="testimonial__details">
					<div class="testimonial__info">
						<h4 class="testimonial__name">Deepak Goyal </h4>
						<h5 class="testimonial__job">Student</h5>
					</div>
				</div>
			</div>
			
        </section>
        
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default AllReviewsPage;
