import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import PageBanner from "../components/about/PageBanner";
import Counter from "../components/Counter";
import Footer from "../components/Footer/Footer";
import { Fade } from "react-awesome-reveal";
import AboutCube from "../components/about/AboutCube";
import ImageMasonry from "../components/masonary/MasonaryAbout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTranslations } from "../actions/languageActions";
import NewHeader from "../components/Header/NewHeader";
import Loading from "../components/loading/Loading";
import FormModal from "../elements/FormModal";
import { Helmet } from "react-helmet-async";
// import Typewriter from "typewriter-effect";
const handleChnge = () => {
  onclick = "window.print()";
};
const DemoPage = () => {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
  
  useEffect(() => {
    dispatch(fetchTranslations(selectedLanguage));
  }, [dispatch, selectedLanguage]);
  
  if (!translations) {
    return <div>
      <Loading/>
    </div>; // Show loading state while translations are being fetched
  }
  
  const { aboutus } = translations;
  const industryList = aboutus.industry.list;
  const learnUs = aboutus.learnUs.list;
  return (
    <>
      <Helmet>
<title>OBA | Best Certificate & Diploma Courses | Signet Institute</title>
<meta name='description' content='Signet Institute of Australia are BASED IN MELBOURNE, SYDNEY & ADELAIDE. (13+ YEARS Experience) Providing Best Certificate & Diploma Courses.'data-rh="true" />
</Helmet>
      <NewHeader />
      {/* <PageBanner pageName={"About us"} /> */}

      {/* <Fade delay={1e3} triggerOnce cascade damping={1e-5}> */}

      {/* page banner */}

      {/* page banner */}
      {/* Page Banner End */}

      <div className="about-sec-">
        <section class="pt-150 nanhe">
        
          <div className="container">
            <div className="row">
              <div className="col-md-6 pt-60">
                <h2 className="title-banner ">
                Are you an international nurse looking to become a qualified nurse in Australia?

                </h2>
                <a href="#popup1" className="theme-btn my-15">
                       Get Started 
                </a>
                
                <a href="#enrol" className="theme-btn mx-3 my-15">
                      Enrol Now 
                </a>
              </div>
              <div className="col-md-6 ">
                <div className="banner-ab-image text-center">
                  <img src="/img/policy/obaa_.jpeg"/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="about-page-section pt-60 pb-65 rpt-90">
            <div className="container">
                <div className="row">
                   <div className="col-md-12"> 
                   <p>If you're an international nurse looking to advance your career in Australia, the Outcome Based Assessment (OBA) preparatory program could be your ticket to new opportunities. Launched in March 2020, OBA preparatory program has been a game-changer for nursing and midwifery professionals worldwide.</p>                       
                        <p>The OBA program in Australia aims to pave the way for permanent visa opportunities for internationally qualified nurses and midwives. However, before starting work as a nurse or midwife in Australia, those trained overseas need to apply for and gain registration with the Nursing and Midwifery Board of Australia (NMBA).</p>
                    </div>
                    <div className="col-md-12 pt-20">
                    <div class="whitebg">
                          <h4>About the course</h4>
                            <p>This specialised initiative mandated by the Nursing and Midwifery Board of Australia (NMBA) under the Australian Health Practitioners Regulation Authority (AHPRA), is designed to prepare overseas qualified nurses for the Outcome Based Assessment (OBA). This assessment is a standardised evaluation introduced by AHPRA to determine readiness for licensure as an entry-level nurse in Australia.  
</p>
                            <p>As part of this course, candidates will undergo training specifically tailored to prepare them for the OSCE and NCLEX, another essential component of the OBA process. The NCLEX-RN, which stands for the National Council Licensure Examination for Registered Nurses (RN), is a standardised test administered by AHPRA, assesses if a candidate meets the requirements to work as an entry-level healthcare professional in Australia. To obtain RN status in Australia, candidates must successfully pass this exam, which is available to those who have completed an accredited nursing program. The exam is designed comprehensively, covering various nursing concepts. The OSCE exam, which stands for Objective Structured Clinical Exam (OSCE), evaluates nursing proficiency and fosters critical thinking skills, and our course at Signet Institute of Australia is designed to equip individuals with the necessary knowledge and skills to excel in these assessments. </p>
                            <p><strong>The primary aims of the course are as follows:</strong></p>
                            <p><ul><li>Prepare participants sufficiently for the successful completion of the Outcome Based Assessment for Australian Registered Nurses.</li><li>Facilitate overseas registered nurses in participating in a supervised and supported training in an Australian healthcare simulated setting prior to the OSCE assessment and/or assist them with NCLEX preparation.</li></ul></p>
                        
                        </div>
                        <div class="whitebg">
                          <h4>Program Options </h4>
                          <img src="/img/policy/oba-course.png"/>
                              <p><strong>OPTION A - Recommended for IQNMs</strong></p>
                              <p>RN-OFFSHORE- Self-Paced Learning (Online-Up to 40 hours) + (10 sessions- Face to face- Lab tutorial & practice + 1 Mock OSCE -2 weeks) – prior scheduled OSCE by AHPRA</p>
                              <p><ul><li><strong>SELF-PACED ONLINE</strong> </li>
                              <ol>1.	Online discussion on NCLEX-RN MCQs </ol>
                              <ol>2.	Trainer provides continuous support and guidance to candidates via teams or emails- providing feedback</ol>
                              <ol>3.	Pre-recorded tutorial on practical skills will be provided to off-shore candidates</ol>
                              </ul></p>
                              <p><ul><li><strong>10 SESSIONS OF FACE TO FACE ON CAMPUS</strong> </li>
                              <ol>1.	Pre- briefing- on rules, regulation, nursing standards and case scenarios </ol>
                              <ol>2.	Introduction to various types of documentation</ol>
                              <ol>3.	Trainer demonstrates the clinical skills </ol>
                              <ol>4.	Candidate practice skills- using step- by-step checklist </ol>
                              <ol>5.	Debriefing and feedback session with trainer </ol>
                              <ol>6.	MOCK OSCE x 1 per candidate </ol>
                              </ul></p> 

                              <p><strong>OPTION B</strong></p>
                              <p>RN ONSHORE-(Online NCLEX-RN review- 2 weeks) + (Face to face training + tutorial + 1 Mock OSCE- 10 session- 2 weeks)</p>
                              <p><ul><li><strong>SELF-PACED ONLINE </strong> </li>
                              <ol>1.	Online discussion on NCLEX-RN MCQs  </ol>
                              <ol>2.	Trainer provides continuous support and guidance to candidates via teams or emails- providing feedback</ol>
                              </ul></p>
                              <p><ul><li><strong>10 SESSIONS OF FACE TO FACE ON CAMPUS</strong> </li>
                              <ol>1.	Pre- briefing- on rules, regulation, nursing standards and case scenarios </ol>
                              <ol>2.	Introduction to various types of documentation</ol>
                              <ol>3.	Trainer demonstrates the clinical skills </ol>
                              <ol>4.	Candidate practice skills- using step- by-step checklist </ol>
                              <ol>5.	Debriefing and feedback session with trainer </ol>
                              <ol>6.	MOCK OSCE x 1 per candidate </ol>
                              </ul></p> 

                              <p><strong>OPTION C</strong></p>
                              <p><strong>RN ONSHORE (CRUSH COURSE) (5 SESSIONS) For advance candidates with >5 years’ experience</strong></p>
                              <p><ul>
                              <ol>1.	Trainer provides pre-briefing on Australian Nursing standards, review case scenarios</ol>
                              <ol>2.	Candidate practice skills- using step- by-step checklist</ol>
                              <ol>3.	Trainer – debrief and provide feedback</ol>
                              <ol>4.	Additional cost for mock OSCE </ol>
                              </ul></p> 
                          </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-2"><h4>Delivery </h4></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>The Blended Learning program offers a combination of lab demonstration and practice sessions along with e-learning delivery. Lab
sessions are conducted in a well-equipped nursing lab with mannequins and necessary devices. E-learning is facilitated through the
Canvas Learning management system, providing resources for NCLEX and OSCE preparation, medication calculation, and nursing
practices regulation in Australia.</p>
                            </div>
                          </div>
                        </div>
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-3"><h4>Location </h4></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>Melbourne, 341 Queen St, Level 5, 9, Melbourne, Victoria 3000.  </p>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div class="faq-drawer">
                          <input class="faq-drawer__trigger" id="faq-drawer-4" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-4"><h4>Requirements</h4></label>
                          <div class="faq-drawer__content-wrapper">
                            <div class="faq-drawer__content">
                            <p>
                            <ul><li>Must be a qualified Registered Nurse from their respective countries and completed self- check assessment with AHPRA. <a class="gunu" href="https://www.nursingmidwiferyboard.gov.au/Accreditation/IQNM/Self-check-and-Portfolio/Completing-the-Self-check.aspx">https://www.nursingmidwiferyboard.gov.au/Accreditation/IQNM/Self-check-and-Portfolio/Completing-the-Self-check.aspx</a></li>
                            <li>Must be in Stream B* </li>
                            </ul></p>
                            </div>
                          </div>
                        </div>
                        
                        
                    </div>
                    <div id="enrol" class="col-md-12 pt-40 text-center">
                        <h4>Join us at Signet Institute and unlock your potential as a nurse in Australia. With our OBA program, you'll receive the guidance and support
you need to thrive in your nursing career</h4>
                        <p class="text-center mb-30">Your journey to success starts here.</p>
                    </div>
                    <iframe src="https://signetinstituteofaustralia.getomnify.com/widgets/COXK3V4CCKW7YS12SS3F8D" frameborder="0" scrolling="yes" seamless="seamless"></iframe>
                  <div id="popup1" class="overlay">
                        <div class="popup">
                          <a class="close" href="#">&times;</a>
                          <div class="content">
                          <p><iframe src="https://forms.zohopublic.com.au/signetinstituteofaustralia/form/OBAResgistrationForm/formperma/Mpy7lxB9_j83A5CH-eYoehUKa-BlS_3QRtXZX6tDbYc" frameborder="0" scrolling="yes" seamless="seamless"></iframe></p>
                          </div>
                        </div>
                      </div>
                </div>
            </div>
        </section>
      </div>
      {/* </Fade> */}
      <Footer />
    </>
  );
};
export default DemoPage;
