import React from "react";
import Typewriter from "typewriter-effect";
import styled from "styled-components";

const MyTitleMessage = styled.h1`
    position: absolute;
    width: 100%;
    top: 3rem;
    z-index: 1;
    margin-top: 0;
    text-align: center;
  strong {
    font-size: 1.25em;
  }
  div {
    color: ${props => props.theme.titleColor};
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 20px;
    }
    .sub {
      font-size: 15px;
      letter-spacing: 2px;
    }
  }
`;

const TitleMessage = () => (
    <MyTitleMessage>
      <div className="titleMessage">
        <div className="heading">
          <div className="main text-center mb-3">
            
            <span>
              <strong>We Are Excellence in</strong>

            </span>
          </div>
          <div className="sub">
            <Typewriter
              options={{
                strings: ["Title One", "Title Two", "Title Three"],
                autoStart: true,
                loop: true,
                delay: 50,
                className:"options-sub"
              }}
            />
          </div>
        </div>
      </div>
    </MyTitleMessage>
  );
  
  export default TitleMessage;
  