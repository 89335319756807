import { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranslations } from '../../actions/languageActions';
import { useParams } from 'react-router-dom';
import { slugify } from '../../utils';
import Loading from '../../components/loading/Loading';

function ExternalCodeTable() {
    const params = useParams();
  const courseSlug = params.slug;
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
  const translations = useSelector((state) => state.language.translations[selectedLanguage]);
    
    useEffect(() => {
      dispatch(fetchTranslations(selectedLanguage));
    }, [dispatch, selectedLanguage]);
    
    if (!translations) {
      return <div><Loading/></div>; // Show loading state while translations are being fetched
    }
    
    const { homepage } = translations;
    const Data = homepage.program.coursesList;
    console.log("Data",Data)
    const getCourseData =Data && Data.filter(data => slugify(data.mainTitle) === courseSlug);
    const detailsService = getCourseData[0];
    return (
    <div class="col-md-12">
       <p>
          {
    detailsService.externalCode.externalCodeList.map((data)=>(
   <>
   <h4>{data.title}</h4>
   <span>{data.data}</span>
   </>
    ))
 }
  </p>
    </div>
  );
}

export default ExternalCodeTable;